import { Box, IconButton, type Theme, useMediaQuery, useTheme } from "@mui/material";
import { not } from "ramda";
import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { FormattedMessage } from "react-intl";

import { PORTAL_BUTTON } from "@hey-lady/shared/helpers/const";
import { FontWeight, Spacing } from "@hey-lady/shared/helpers/enum";

import Button from "@hey-lady/ui/atoms/Button";
import Typography from "@hey-lady/ui/atoms/Typography";

import { useAuth } from "@hey-lady/shared/hooks/user";
import IconCross from "@hey-lady/ui/icons/IconCross";
import IconLogo from "@hey-lady/ui/icons/IconLogo";
import { useNavigate } from "react-router";

/**
 * Types
 */
interface Props {
  naked?: boolean;
  fixed?: boolean;
  absolute?: boolean;
  homeRedirect?: boolean;
  redirectTo?: string;
  hideLogo?: boolean;
  showLogoOnMobile?: boolean;
}

/**
 * Styles
 */
const useStyles = makeStyles<{ homeRedirect: Props["homeRedirect"] }>()(
  (theme: Theme, { homeRedirect }) => ({
    navbar: {
      left: "0px",
      right: "0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop: theme.spacing(Spacing.xl),
      paddingLeft: theme.spacing(Spacing.xl),
      paddingRight: theme.spacing(Spacing.xl),
      [theme.breakpoints.down("md")]: {
        paddingTop: theme.spacing(Spacing.m),
        paddingLeft: theme.spacing(Spacing.xxl),
        paddingRight: theme.spacing(Spacing.xxl),
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(Spacing.l),
        paddingRight: theme.spacing(Spacing.l),
        paddingTop: "unset",
      },
    },
    logo: {
      width: "147px",
      height: "80px",
      [theme.breakpoints.down("md")]: {
        width: "94px",
      },
    },
    fixed: {
      position: "fixed",
      [theme.breakpoints.down("md")]: {
        position: "unset",
      },
    },
    absolute: {
      position: "absolute",
      top: 0,
    },
    button: {
      backgroundColor: theme.palette.white,
      width: "40px",
      height: "40px",
      [theme.breakpoints.down("md")]: {
        position: homeRedirect ? "absolute" : "fixed",
        top: homeRedirect ? "52px" : theme.spacing(Spacing.l),
        right: theme.spacing(Spacing.l),
      },
      [theme.breakpoints.down("sm")]: {
        top: homeRedirect ? theme.spacing(Spacing.xl) : theme.spacing(Spacing.sm),
        right: "12px",
      },
    },
    icon: {
      width: "16px",
      height: "16px",
      [theme.breakpoints.down("sm")]: {
        width: "10px",
        height: "10px",
      },
    },
  }),
);

const NakedAppBar: React.FC<Props> = ({
  fixed,
  absolute,
  homeRedirect,
  naked,
  redirectTo,
  hideLogo,
  showLogoOnMobile,
}: Props) => {
  const theme = useTheme();
  const { classes, cx } = useStyles({ homeRedirect });
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const { authenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<Record<string, string>>();
  const isPublished = params.published;

  const getAction = () => {
    if (redirectTo) {
      navigate(redirectTo);
      return;
    }
    if (isPublished) {
      navigate("/memberzine/featured");
      return;
    }
    if (homeRedirect) {
      navigate("/home");
      return;
    }
    if (location.state?.topicsPage) {
      navigate(`/topics/${location.state.topicsPage}`);
      return;
    }
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate("/home");
    }
  };

  const renderers = {
    button: () => {
      if (naked) {
        return null;
      }
      if (authenticated) {
        return (
          <IconButton className={classes.button} onClick={getAction}>
            <IconCross htmlColor={theme.palette.gray.default} className={classes.icon} />
          </IconButton>
        );
      }
      return (
        <Link to="/auth/login">
          <Button variant="outlined" color="primary" size="small">
            <Typography color="primary" variant="body2" weight={FontWeight.Bold}>
              <FormattedMessage id="AUTH_LOG_IN" />
            </Typography>
          </Button>
        </Link>
      );
    },
  };

  return (
    <Box
      pt={Spacing.xl}
      px={Spacing.xl}
      className={cx(classes.navbar, { [classes.fixed]: fixed, [classes.absolute]: absolute })}
    >
      {(matches || showLogoOnMobile) && not(hideLogo) && (
        <>
          {authenticated ? (
            <Link to="/home">
              <IconLogo color="primary" className={classes.logo} />
            </Link>
          ) : (
            <IconLogo color="primary" className={classes.logo} />
          )}
        </>
      )}
      <div id={PORTAL_BUTTON}> </div>
      {renderers.button()}
    </Box>
  );
};

export default NakedAppBar;
